<template>
  <div>
    <UserSettings ref="userSettingsDialog" :lockViewerOptions="false"/>
    <b-navbar type="dark" variant="dark">
      <b-navbar-brand href="#"><img src="../assets/logo.png" class="d-inline-block align-top" alt="Saince" height="30"></b-navbar-brand>
      <b-dropdown class="ml-1" title="Customer List" :size="buttonSize" variant="info">
        <template #button-content>
          <b-icon icon="building"></b-icon>
          <span v-if="customerId!=''"> {{ customers[customerId].name }}</span>
          <span v-else> TBD</span>
        </template>
        <b-dropdown-item v-for="c in customerIds" :key="c" @click="changeCustomer(c)">
          <span v-if="c==customerId"><b-icon icon="toggle-on" variant="info"></b-icon> <strong>{{ customers[c].name }}</strong></span>
          <span v-if="c!=customerId"><b-icon icon="toggle-off"></b-icon> {{ customers[c].name }}</span>
        </b-dropdown-item>
      </b-dropdown>
      <b-navbar-nav>
        <b-badge class="ml-1" variant="secondary" show :size="buttonSize">{{reportItemsCount}} Exams</b-badge>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-badge class="ml-1 d-none d-sm-block" variant="secondary" show :size="buttonSize">Last run: {{ lastRun }}</b-badge>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-button-group class="ml-2" :size="buttonSize">
          <b-button @click="handleClose" variant="secondary" title="Return to Worklist">
            <b-icon icon="file-x"></b-icon>
          </b-button>
        </b-button-group>
        <b-dropdown class="ml-1" title="User" :size="buttonSize" right>
          <template #button-content>
            <b-icon icon="person"></b-icon>
          </template>
          <b-dropdown-header>{{user}}</b-dropdown-header>
          <b-dropdown-item @click="userSettings"><b-icon icon="gear-fill"></b-icon> Settings</b-dropdown-item>
          <b-dropdown-item @click="userHistory"><b-icon icon="card-list"></b-icon> History</b-dropdown-item>
          <b-dropdown-item @click="profile"><b-icon icon="person-fill"></b-icon> Profile</b-dropdown-item>
          <b-dropdown-item @click="logout"><b-icon icon="box-arrow-right"></b-icon> Logout</b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>  
</template>
<script>
import UserSettings from './UserSettings.vue'

export default {
  name: 'mgmtReportsNavBar',
  components: {
    UserSettings
  },
  data() {
    return {
      buttonSize: "sm"
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  computed: {
    customerId() {
      return this.$store.state.customerId
    },
    customerIds() {
      return Object.keys(this.$store.state.customers)
    },
    customers() {
      return this.$store.state.customers
    },
    reportItemsCount() {
      return this.$store.state.mgmtReportItems.length
    },
    reportTS() {
      return this.$store.state.mgmtReportTS
    },
    lastRun() {
      return (this.reportTS == 0) ? 'N/A' : new Date(this.reportTS).toLocaleString(this.$store.state.locale)
    },
    user() {
      return this.$store.getters.user || 'User Name Not Set'
    }
  },
  methods: {
    handleClose() {
      // Clean up any temporary worklist created to view a study. Worklist will refresh
      // when mounted.
      //
      this.$store.commit('changeWorklist', [])
      this.$store.commit('changeWorklistSorted', [])
      this.$router.replace('worklist')
    },
    handleResize() {
      this.buttonSize = (window.innerWidth < 767) ? "sm" : "md"
    },
    changeCustomer(customerId) {
      if (customerId != this.customerId) {
        var newUrl = `${window.location.origin}/?cid=${customerId}&n=1`
        this.$log.info(`Handling customer change redirectUrl=${newUrl}`);
        window.location.href = newUrl
      }
    },
    logout() {
      this.$router.replace('logout')
    },
    userHistory() {
      var answer = true
      if (this.$store.state.activeComponent == 'ReportEditor') {
        answer = window.confirm('Do you really want to leave while editing report?')
      }
      if (answer) {
        this.$store.commit('changeActiveComponent', 'UserHistory')
      }
    },
    userSettings() {
      this.$refs.userSettingsDialog.show()
    },
    profile() {
      try {
        window.open(this.$store.state.keycloak.createAccountUrl());
      }
      catch(err) {
        this.$log.error('Failed to open account profile: '+err);
      }
    }
  }
}    
</script>
<style scoped>
</style>